import axios from '@/libs/axios'

export default class WebOperadores {

    index(query) {
        return axios.get(`/v2/WebOperadores/GetAll`, {params: query});    
    }

    store(payload) {
        return axios.post(`/v2/WebOperadores/Store`, payload);    
    }

    update(payload) {
        return axios.put(`/v2/WebOperadores/Update`, payload);    
    }

    destroy(id) {
        return axios.put(`/v2/WebOperadores/Destroy/${id}`);    
    }
}
