import axios from '@/libs/axios'

export default class Customers {

    getAll(query) {
        return axios.get(`/v2/Clientes/All`, { params: query })        
    }

    find(idCliente) {
        return axios.get(`/v2/Clientes/Find/${idCliente}`)
    }

    getCreditLimit (query) {
        return axios.get(`/v2/Clientes/GetAllCreditLimit`, { params: query})
    }

    storeCreditLimit(idCliente, payload) {
        return axios.post(`/v2/Clientes/StoreCreditLimit/${idCliente}`, payload)
    }

    revertCreditLimit(hours, id) {
        return axios.get(`/v2/Clientes/RevertCreditLimit`, {params: {hours: hours, id: id}})
    }

    CheckCheckRevertCreditLimit() {
        return axios.get(`/v2/Clientes/CheckCheckRevertCreditLimit`)
    }

    getCharges(customerId) {
        return axios.get(`/v2/Clientes/GetCharges/${customerId}`)
    }
}
