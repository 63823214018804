<template>
    <div>
        <VueHtml2pdf
        id="viewer"
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="190"
        :pdf-quality="2"
        :filename="routing.folio"
        :manual-pagination="true"
        pdf-format="letter"
        pdf-orientation="portrait"
        ref="html2Pdf"
        @progress="handlePdfProgress($event)"
        :html-to-pdf-options="pdfOptions"
        >        
          <section v-if="routing !== undefined" slot="pdf-content" class="invoice-preview-wrapper" 
            style="width: 210mm; margin: 0 auto; color: black!important; font-size: 7px!important;">            
            <section class="pdf-item">
                <div class="pdf-header_ m-2">
                    <b-row class="_match-height invoice-preview" style="font-size: 9px!important;">
                        <b-col cols="3" md="3">
                            <h6 style="color:black">
                                <strong>{{ folio }}</strong>
                                <small style="color: black;"> Impreso el {{ customFormatDate('', 'DD/MM/YYYY H:mm:ss a') }}</small><br>
                                <strong>Instrucciones: {{ diasPlazoLabel }}</strong>
                            </h6>
                            <small style="font-size: 10px!important;"><strong>Chofer: </strong> {{ routing.webOperadores.nombre }} {{ routing.webOperadores.apellidos }}</small><br>
                            <small style="font-size: 10px!important;"><strong>Unidad: </strong> {{ routing.webCamiones.identificador }} {{ routing.webCamiones.placas }}</small>
                        </b-col>
                        <b-col cols="6" md="6">
                            <div style="text-align: center!important;">
                                <h6 style="color:black">
                                    {{ conexion.nombre }}
                                </h6>
                                <h4 style="color: black;">Documento de Entrega</h4>
                                <h6 style="color: black;">Simplificado</h6>
                            </div>
                        </b-col>
                        <b-col cols="3" md="3">
                            <h6 style="color:black; margin-left: 15px!important;" v-if="sales.length > 0"><small>TICKET ({{sales.length}}):</small> {{ sales[0].folio }} </h6>
                            <svg ref="barcode" style="margin-top: -7.9px!important;"></svg>
                            <div style="border-radius: 0.428rem; font-size: 7px!important;">
                                <small style="font-size: 10px!important;">
                                    <span style="margin-left: -90px!important;"><strong>Importe Fact:</strong> {{ formatCurrency(routing.importe_Neto) }} </span>
                                    <span style="margin-left: 10px!important; text-align: right!important;"><strong>Saldo Fact:</strong> {{ formatCurrency(routing.docto.doctosPVSaldo[0].saldo_Cargo) }}</span>
                                </small><br>
                                <small style="margin-left: -90px!important;font-size: 10px!important;"><strong>Vendedor: </strong> {{ routing.nombre_Vendedor }}</small><br>
                            </div>
                        </b-col>
                        <b-col cols="12" md="12" style="margin-top: -20px!important;">                                        
                            <b-row class="invoice-spacing match-height">
                                <b-col cols="12" xl="4" class="_p-0" style="border: 1px solid black;"> 
                                    <div style="border-radius: 0.428rem; padding: .5rem; font-size: 7px!important;">
                                        <small style="font-size: 9px!important; margin-left: -7px!important;"><strong>Tipo Cliente:</strong> {{ routing.tipo_Cliente }}</small><br>
                                        <small style="font-size: 9px!important; margin-left: -7px!important;"><strong>Cliente:</strong> {{ routing.nombre_Cliente }}</small><br>
                                        <small style="font-size: 9px!important; margin-left: -7px!important;"><strong>Consignatario:</strong> {{ routing.nombre_Consig }}</small><br>
                                        <small style="font-size: 9px!important; margin-left: -7px!important;"><strong>Calle:</strong> {{ routing.cliente.calle }}</small><br>
                                        <small style="font-size: 9px!important; margin-left: -7px!important;"><strong># Ext.</strong> {{ routing.cliente.num_Exterior }} <strong># Int.</strong> {{ routing.cliente.num_Interior }}</small><br>
                                        <small style="font-size: 9px!important; margin-left: -7px!important;"><strong>Teléfono:</strong> {{ routing.cliente.telefono1 }}</small>
                                    </div>
                                </b-col>

                                <b-col cols="12" xl="2" class="p-0" style="padding: .10rem; border: 0px!important;" >
                                    <div class="border-dark_ text-center" style="border-radius: 0.428rem; padding: .5rem; font-size: 10px!important; text-align: center!important;">
                                        <strong style="color: black!important;">REFERENCIA</strong>
                                        <div v-if="routing.direccion_Referencia">
                                        <qr-code 
                                            :text="routing.direccion_Referencia"
                                            :size="100"                                        
                                            error-level="L">
                                        </qr-code>
                                        </div>                                    
                                    </div>                                                   
                                </b-col>

                                <b-col cols="12" xl="3" class="p-0" style="border: 1px solid black;">
                                    <div class="border-dark_ text-center" style="border-radius: 0.428rem; padding: .5rem; font-size: 10px!important;">
                                        <strong style="color: black!important;">NOTAS DEL CLIENTE</strong><br>
                                        <small style="font-size: 9px!important;  word-break: break-word;" v-html="limitedObservaciones"></small>
                                    </div>
                                </b-col>
                                <b-col cols="12" xl="3" class="_p-0 _pb-1" style="border: 1px solid black;">
                                    <div class="border-dark_ text-center" style="border-radius: 0.428rem; padding: .5rem; font-size: 10px!important;">
                                        <strong style="color: black!important;">DESCRIPCIÓN FACTURA</strong><br>
                                        <small style="font-size: 9px!important;  word-break: break-word;" v-html="routing.docto.descripcion"></small>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <hr style="border-top: 2px solid black; margin-top: -15px!important;">
               </div>

                <div class="m-2" style="margin-top: -15px!important;">
                    <div v-for="(chunk, index) in chunkedData" :key="index">
                        <table class="table custom-inner-table b-table position-relative b-table-responsive text-small small table-sm" 
                        style="border-collapse: collapse; margin-top: -5px!important;">
                        <thead>
                            <tr>
                                <th style="text-align: center!important; width: 30px!important; font-size: 7px!important;">CANT</th>
                                <th style="text-align: center!important; width: 30px!important; font-size: 7px!important;">PEND</th>
                                <th style="text-align: center!important; width: 50px!important; font-size: 7px!important;">UM</th>
                                <th style="text-align: center!important; width: 30px!important; font-size: 7px!important;">CLAVE PROV</th>
                                <th style="font-size: 7px!important;">ARTICULO</th>
                                <th style="width: 25px!important;font-size: 7px!important;">ENT<span style="color:white!important;">_</span></th>
                                <th style="width: 10px!important;font-size: 7px!important;">PEND</th>
                                <th style="width: 25px!important;font-size: 7px!important;">ENT<span style="color:white!important;">_</span></th>
                                <th style="width: 10px!important;font-size: 7px!important;">PEND</th>
                                <th style="width: 25px!important;font-size: 7px!important;">ENT<span style="color:white!important;">_</span></th>
                                <th style="width: 10px!important;font-size: 7px!important;">PEND</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, itemIndex) in chunk" :key="itemIndex" class="custom-row-height">
                                <td style="width: 30px!important;font-size: 8px!important; color: black!important;">{{ item.um_Total }}</td>
                                <td style="width: 30px!important;font-size: 8px!important; color: black!important;">{{ item.pendiente }}</td>
                                <td style="width: 50px!important;font-size: 8px!important; color: black!important;">{{ item.um }}</td>
                                <td style="width: 30px!important;font-size: 8px!important;">{{ item.clave_Articulo_Proveedor }}</td>
                                <td style="width: 750px!important;font-size: 8px!important; color: black!important;">{{ item.articulo }}</td>                                
                                <td style="color:black!important; width: 15px!important;">&nbsp; {{ item.pendiente <= 0 ? '&#x1694;&#x1694;&#x1694;' : '' }}  </td>
                                <td style="color:black!important; width: 10px!important; border-left: 0px solid #d3d3d3!important;">{{ item.pendiente <= 0 ? '&#x1694;&#x1694;&#x1694;' : '' }}</td>
                                <td style="color:black!important; width: 15px!important;">&nbsp;{{ item.pendiente <= 0 ? '&#x1694;&#x1694;&#x1694;' : '' }}   </td>
                                <td style="color:black!important; width: 10px!important; border-left: 0px solid #d3d3d3!important;">{{ item.pendiente <= 0 ? '&#x1694;&#x1694;&#x1694;' : '' }}</td>
                                <td style="color:black!important; width: 15px!important;">&nbsp;{{ item.pendiente <= 0 ? '&#x1694;&#x1694;&#x1694;' : '' }}   </td>
                                <td style="color:black!important; width: 10px!important; border-left: 0px solid #d3d3d3!important;">{{ item.pendiente <= 0 ? '&#x1694;&#x1694;&#x1694;' : '' }}</td>

                            </tr>
                        </tbody>
                        </table>
                        <div class="text-center" v-if="(index + 1) === chunkedData.length" style="margin-top: 20px;">
                        <p style="font-size: 10px!important; text-align: center;">
                            Por la presente, confirmo que he recibido todos los materiales listados anteriormente de manera completa y en buenas condiciones. 
                            Estoy de acuerdo en que no hay discrepancias ni faltantes en la entrega<br>
                            ________________________________________________________________________<br>
                            <strong>{{ routing.nombre_Cliente }}</strong>
                        </p>
                        </div>
                        <div class="text-center" style="font-size: 8px!important;">Página {{ index + 1 }} de {{ chunkedData.length }}</div>
                        <div v-if="index < chunkedData.length - 1" v-page-break></div>
                        <p><br></p>
                        
                    </div>
                </div>
            </section>
          </section>
        </VueHtml2pdf>
    </div>
</template>

<script>
import { ref, watch, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
    BRow, BCol, BCard, BCardBody, BCardHeader, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'

import VueHtml2pdf from 'vue-html2pdf'
import JsBarcode from 'jsbarcode';

import DeliveryRoutesProvider from '@/providers/DeliveryRoutes'
const DeliveryRoutesResource = new DeliveryRoutesProvider();

import DoctosPVProvider from "@/providers/DoctosPV"
const DoctosPVResource = new DoctosPVProvider()

export default {
    directives: {
        Ripple,
        'b-toggle': VBToggle,
    },
    components: {
        BRow,
        BCol,
        BCard,
        BCardBody,
        BCardHeader,
        BTableLite,
        BCardText,
        BButton,
        BAlert,
        BLink,

        VueHtml2pdf,
    },
    props: {
        routing: {
            type: Object,
            default: () => { },
            required: true,
        },
        doctoPvId: {
            type: Number,
            default: () => null,
            required: true,
        },
        folio: {
            type: String,
            default: () => '',
            required: true,
        }
    },
    data() {
        return {
            loading: false,
            articles: [],
            currentPage: 1,
            totalPages: 1,
            pdfOptions: {
                margin: [5, 0, -5, 5], // top, left, buttom, right
                image: { type: 'jpg', quality: 0.98 },
                html2canvas: {
                    scale: 2,
                    // bottom: 0,
                    // top: 5,
                    // letterRendering: true,
                },
                jsPDF: {
                    unit: 'mm',
                    format: 'letter',
                    orientation: 'portrait',
                },
            },
            showPdfContent: false,
            slashIcon: require('@/assets/images/slash.png'),
            sales: [],
        }
    },
    computed: {
        limitedObservaciones() {
            if (this.routing.observaciones == null) return ''
            return this.routing.observaciones.length > 150
                ? this.routing.observaciones.substring(0, 150) + '...'
                : this.routing.observaciones;
        },
        diasPlazoLabel() {
            if (this.routing.docto && this.routing.docto.doctosPVSaldo && this.routing.docto.doctosPVSaldo.length > 0 && this.routing.docto.doctosPVSaldo[0].saldo_Cargo == 0) {
                return 'PAGADA'
            }

            if (this.routing.cliente.dias_Plazo == 0) {
                return 'P.A.R'
            }
            if (this.routing.cliente.dias_Plazo > 0) {
                return 'CLIENTE FIRMA'
            }
        },
        diasPlazoVariant() {
            if (this.routing.docto && this.routing.docto.doctosPVSaldo && this.routing.docto.doctosPVSaldo.length > 0 && this.routing.docto.doctosPVSaldo[0].saldo_Cargo == 0) {
                return 'success'
            }

            if (this.routing.cliente.dias_Plazo == 0) {
                return 'danger'
            }

            if (this.routing.cliente.dias_Plazo > 0) {
                return 'warning'
            }
        },
        conexion() {
            return store.getters['auth/getConexion']
        },
        chunkedData() {
            const firstChunkSize = 17;
            const otherChunkSize = 22;
            let chunks = [];
            let i = 0;

            // Primer grupo de 5 elementos
            if (this.articles.length > 0) {
                chunks.push(this.articles.slice(i, i + firstChunkSize));
                i += firstChunkSize;
            }

            // Grupos restantes de 6 elementos
            while (i < this.articles.length) {
                chunks.push(this.articles.slice(i, i + otherChunkSize));
                i += otherChunkSize;
            }

            return chunks;
        }
    },
    mounted() {
        // Generate barcode
        JsBarcode(this.$refs.barcode, this.routing.docto_Pv_Id, {
            format: 'CODE128',
            displayValue: true,
            // width: 100, // Ancho en píxeles
            height: 35 // Altura en píxeles
        });
    },
    methods: {   
        async getDoctos() {
			try {
				//Convertir en un array el folio de tickets
				const folios = this.routing.folio_Tickets.split(',').map(folio => parseInt(folio)).filter(folio => !isNaN(folio));
				const { data } = await DoctosPVResource.getFoliosTickets(folios);
				this.sales = data.data;  
			}catch (error) {
				console.log(error);
			}
		},
        handlePdfProgress(evt) {
          this.$emit('pdf-progress', evt);
        },
        async getInvoiceArticles() {
            try {
                this.loading = true
                const { data } = await DoctosPVResource.getArticles(this.doctoPvId, "V")
                this.loading = false
                if (data.isSuccesful) {
                    this.articles = data.data.map(item => ({...item, cargado: 0, oldPending: parseFloat(item.pendiente)}))                    
                    //this.routing.articles = this.articles
                } else {
                    this.danger(data.message)
                }
            } catch (e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        async printDocument() {

            console.log(`Imprimiendo documento simplificado: ${this.doctoPvId}`)
            await this.getDoctos();
            await this.getInvoiceArticles()
            this.showPdfContent = false
            this.$nextTick( async () => {
                this.showPdfContent = true
                this.pdfOptions.filename = this.routing.folio
                this.$refs.html2Pdf.generatePdf()

                //Set print document to true
                try {
                    const { data } = await DeliveryRoutesResource.setPrintDocument(this.routing.id)
                    if(data.isSuccesful) {
                        console.log('Documento marcado como impreso')
                    }                    
                } catch (e) {
                    console.log(JSON.stringify(e))
                }
            })          
        }
    },
    setup(props) {

        // const sales = ref([]);
		// const getDoctos = async () => {
		// 	try {
		// 		//Convertir en un array el folio de tickets
		// 		const folios = props.routing.folio_Tickets.split(',').map(folio => parseInt(folio)).filter(folio => !isNaN(folio));
		// 		const { data } = await DoctosPVResource.getFoliosTickets(folios);
		// 		sales.value = data.data;
        //         console.log(sales)
		// 	}catch (error) {
		// 		console.log(error);
		// 	}
		// }

		// watch(() => props.routing, async (newValue, oldValue) => {
		// 	await getDoctos();
		// });

        // return {
        //     sales,
        // }
    }

}
</script>


<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
th {
    color: black;
}

hr {
    margin-top: 0 !important;
    margin-bottom: 0px !important;
    border: 0;
    border-top: 1px solid black;
}

/* Estilos para la tabla secundaria */
.custom-inner-table {
    border-collapse: collapse;
    /* Combina los bordes de las celdas */
    border: 1px solid gray;
    /* Establece el borde de la tabla secundaria */
    border-radius: 0 !important;
}

.custom-inner-table th,
.custom-inner-table td {
    border: 0.1px solid gray;
    /* Establece el borde de las celdas de la tabla secundaria */
    padding: 1px;
    /* Añade espaciado interno */
    border-radius: 0 !important;
}

/* Estilos específicos para algunos navegadores */
@supports (-moz-appearance:none) {

    .custom-inner-table th,
    .custom-inner-table td {
        border: 1px solid gray;
        /* Restablece el grosor del borde para Firefox */
    }
}

.custom-row-height {
  height: 35px !important; /* Ajusta esta altura según tus necesidades */
}

.pdf-footer {
    text-align: center;
    font-size: 5px !important;
    border-top: 0px solid #000;
    padding-top: 5px;
    margin-top: 5px;
}

</style>