<template>
    <div>
        <b-modal id="modal-center" ref="info-modal" title="Autorizador" @hidden="close" @ok="saveInfo" hide-footer
            no-close-on-backdrop ok-title="Autorizar"
            cancel-title="Cancelar">
            <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
                <validation-observer ref="checkValidation">
                    <b-form class="mt-2" @submit.prevent>

                        <b-col cols="12 mb-75">
                            <label>Usuario (*)</label>
                            <validation-provider #default="{ errors }" name="usuario"
                                rules="required">
                                <b-form-input autofocus v-model="user.userName" class="d-inline-block mr-1"
                                    :state="errors.length > 0 ? false : null" autocomplete="off"
                                    placeholder="Ingresesu usuario" />
                            </validation-provider>
                        </b-col>

                        <b-col cols="12 mb-75">
                            <label>Contraseña (*)</label>
                            <validation-provider #default="{ errors }" name="contraseña" rules="required">
                                <b-form-input ref="userPassword" v-model="user.password" class="d-inline-block mr-1"
                                    placeholder="Ingrese su contraseña" type="password"
                                    :state="errors.length > 0 ? false : null" />
                            </validation-provider>
                        </b-col>

                        <b-row class="mt-4 text-right">
                            <b-col cols="12">
                                <b-button size="sm" variant="outline-secondary" @click="close" class="mr-2">
                                    <span>Cancelar</span>
                                </b-button>
                                <b-button size="sm" type="submit" variant="primary" @click="validateForm">
                                    <span>Autorizar</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
import store from '@/store'
import _ from 'lodash'
import {
    BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BForm
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from "vue-ripple-directive";

import UsersProvider from '@/providers/Users'
const UsersResource = new UsersProvider()


export default {
    name: 'AuthorizationModal',
    components: {
        BRow,
        BCol,
        BCard,
        BCardBody,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,

        //Validations
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    props: ['active'],
    data() {
        return {
            loading: false,
            user: {
                userName: '',
                password: ''
            }
        }
    },
    computed: {
        getUserName() {
            return store.getters['auth/getUser'] ? store.getters['auth/getUser'].userName : ''
        },
        isAutorizator () {
            return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == "9da2306d-48fc-4359-af70-27fb9eeb5440" : false
        },
    },
    methods: {
        close() {
            this.$refs['info-modal'].hide()
            this.$emit("close");
        },
        saveInfo() {
            this.$refs['info-modal'].hide()
            this.$emit("close");
        },
        validateForm() {
            this.$refs.checkValidation.validate().then(success => {
                if (success) {

                    this.loading = true
                    UsersResource.checkAuthorization(this.user).then(response => {
                        console.log(response.data)
                        this.loading = false
                        if (response.data.isSuccesful) {
                            this.$emit("store");
                            this.close();
                        } else {
                            this.danger(response.data.message)
                        }
                    }).catch(error => {
                        this.loading = false
                        this.handleResponseErrors(error)
                    })
                }
            })
        }
    
    },
    watch: {
        active(value) {
            if (value) {
                this.$refs['info-modal'].show()
                
                if (process.env.NODE_ENV === 'development') {
                    this.user = {
                        userName: 'autorizador',
                        password: 'Adm1n2024_'
                    }
                } else {
                    this.user = {
                        userName: this.isAutorizator ? this.getUserName : '',
                        password: ''
                    }
                }

                

                // this.$nextTick(() => {
                //     if (this.isAutorizator) {
                //         this.$refs.userPassword.focus()
                //     }
                // })
            } 
        },
    },
}
</script>