import axios from '@/libs/axios'

export default class Users {
    
    index() {
        return axios.get(`/Cuentas/ListarUsuarios`);    
    }
    
    store(payload) {
        return axios.post(`/Cuentas/Registrar`, payload);
    }

    update(id, payload) {
        return axios.put(`/Cuentas/ActualizarUsuario/${id}`, payload)
    }

    destroy (id) {
        return axios.delete(`/Cuentas/EliminarUsuario/${id}`);    
    }

    checkAuthorization(payload) {
        return axios.post(`/Cuentas/ValidarAutorizacion`, payload);
    }
}
