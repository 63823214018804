<template>
	<div>
		
		<b-button v-if="routing.estatus == 'CLIENTE PASA'" ref="printButton" :disabled="routing.estatus == 'CERRADA'" @click="printDocument"
			size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="secondary">
			<feather-icon icon="PrinterIcon" /> {{ title }}
		</b-button>		
		<b-button v-else ref="printButton" :disabled="!routing.fecha_Asignacion_Chofer || routing.estatus == 'CERRADA'" @click="printDocument"
			size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="secondary">
			<feather-icon icon="PrinterIcon" /> {{ title }}
		</b-button>

		<document-details ref="documentDetails" :doctoPvId="doctoPvId" :folio="folio" :routing="routing" @pdf-progress="handlePdfProgress"/>
		<document-simplified ref="documentSimplified" :folio="folio" :doctoPvId="doctoPvId" :routing="routing" @pdf-progress="handlePdfProgress"/>

	</div>
</template>

<script>
import { ref, watch, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
	BRow, BCol, BCard, BCardBody, BCardHeader, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import EventBus from "@/event-bus"
import VueHtml2pdf from 'vue-html2pdf'
import JsBarcode from 'jsbarcode';

import DeliveryRoutesProvider from '@/providers/DeliveryRoutes'
const DeliveryRoutesResource = new DeliveryRoutesProvider();

import DoctosPVProvider from "@/providers/DoctosPV"
const DoctosPVResource = new DoctosPVProvider()

//Componentes
import DocumentDetails from './documents/DocumentDetails.vue'
import DocumentSimplified from './documents/DocumentSimplified.vue'

export default {
	directives: {
		Ripple,
		'b-toggle': VBToggle,
	},
	components: {
		BRow,
		BCol,
		BCard,
		BCardBody,
		BCardHeader,
		BTableLite,
		BCardText,
		BButton,
		BAlert,
		BLink,

		VueHtml2pdf,

		//Componentes
		DocumentDetails,
		DocumentSimplified,
	},
	props: {
		routing: {
			type: Object,
			default: () => { },
			required: true,
		},
		doctoPvId: {
			type: Number,
			default: () => null,
			required: true,
		},
		folio: {
			type: String,
			default: '',
			required: true,
		},
		title: {
			type: String,
			default: 'Imprimir Hoja de Control',
		},
	},
	data() {
		return {
			loading: false,

		}
	},
	computed: {

	},
	mounted() {
		// Escuchar el evento emitido desde el componente padre (imprimir la hoja de control de la ruta)
		this.$on('trigger-print', (evt) => {
			this.printDocument();
		});

	},
	methods: {
		handlePdfProgress(progress) {
			this.loading = progress < 100
			this.$swal.fire({
				title: `Generando PDF`,
				html: `espere un momento...`,
				timerProgressBar: true,
				timer: 2000,
				showConfirmButton: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			}).then(() => {
				
			})
		},
		printDocument() {

			this.$swal.fire({
				title: `Imprimir Hoja de Control`,
				text: 'Selecciona un formato a imprimir.',
                icon: 'question',
                showCancelButton: true,
                showDenyButton: true,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
                confirmButtonText: 'Detallado',
                denyButtonText: 'Simplificado',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: "btn btn-primary ml-1",
                    denyButton: "btn btn-warning ml-1",
                    cancelButton: "btn btn-outline-danger ml-1 mt-1",
                },
                showClass: {
                    popup: "animate__animated animate__flipInX",
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
					await this.$refs.documentDetails.printDocument();
                },
                preDeny: async () => {
					this.$swal.showLoading();			
					await this.$refs.documentSimplified.printDocument();
					this.$emit('print-simplified');
                },
                allowOutsideClick: () => !Swal.isLoading(),
            }).then(() => {
				
			})
		}
	},
	setup(props) {

		const sales = ref([]);
		const getDoctos = async () => {
			try {
				//Convertir en un array el folio de tickets
				const folios = props.routing.folio_Tickets.split(',').map(folio => parseInt(folio)).filter(folio => !isNaN(folio));
				const { data } = await DoctosPVResource.getFoliosTickets(folios);
				sales.value = data.data;
			}catch (error) {
				console.log(error);
			}
		}

		watch(() => props.routing, async (newValue, oldValue) => {
			// await getDoctos();
		});
	},
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
th {
	color: black;
}

hr {
	margin-top: 0 !important;
	margin-bottom: 0px !important;
	border: 0;
	border-top: 1px solid black;
}

/* Estilos para la tabla secundaria */
.custom-inner-table {
	border-collapse: collapse;
	/* Combina los bordes de las celdas */
	border: 1px solid gray;
	/* Establece el borde de la tabla secundaria */
	border-radius: 0 !important;
}

.custom-inner-table th,
.custom-inner-table td {
	border: 0.1px solid gray;
	/* Establece el borde de las celdas de la tabla secundaria */
	padding: 1px;
	/* Añade espaciado interno */
	border-radius: 0 !important;
}

/* Estilos específicos para algunos navegadores */
@supports (-moz-appearance:none) {

	.custom-inner-table th,
	.custom-inner-table td {
		border: 1px solid gray;
		/* Restablece el grosor del borde para Firefox */
	}
}

// .pdf-header {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   border-bottom: 2px solid black;
//   padding-bottom: 10px;
//   margin-bottom: 10px;
// }

.pdf-footer {
	text-align: center;
	font-size: 5px !important;
	border-top: 0px solid #000;
	padding-top: 5px;
	margin-top: 5px;
}
</style>