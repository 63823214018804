<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">

        <!-- Mostrar el boton para autocompletar -->
        <b-button v-if="showAutocompleteCharge && isEdit && !isReturn"  @click="autoCompleteCharge" variant="outline-warning" size="sm" class="mb-2 text-right float-right">
            <feather-icon icon="ListIcon" />
            Autocompletar
        </b-button>

        <b-overlay :show="loadingImage" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
            <div class="table-responsive">
                <b-table ref="refArticles" class="position-relative b-table-responsive mb-0 text-small small"
                    :items="articles" responsive selectable select-mode="single" :fields="columns" primary-key="_key"
                    show-empty empty-text="No se encontraron resultados" busy.sync="loading" small
                    :sticky-header="isSticky"
                    :no-border-collapse="true"
                    @row-dblclicked="viewDetailArticle">

                    <!-- Empty -->
                    <template slot="empty">
                        <h6 class="text-center mt-1">
                            No se encontraron registros
                        </h6>
                    </template>

                    <!-- Um Total -->
                    <template #cell(um_Total)="data">                    
                        <div :style="`display: flex; align-items: center; gap: 15px`">
                            <feather-icon v-if="data.item.existe_Imagen" icon="ImageIcon" />
                            <span :style="`margin-left: ${data.item.existe_Imagen ? '10px' : '40px'}; text-align: right;`">{{ data.item.um_Total }}</span>
                        </div>
                        <!-- <p>{{ data.item.docto_Pv_Det_Id }}</p> -->
                    </template>


                    <template #cell(articulo)="data">                    
                        <span style="font-size: 10px!important;">
                            {{ data.item.articulo }}
                        </span>
                    </template>

                    <template #cell(um)="data">
                        {{ data.item.um }}
                    </template>

                    <template #cell(entregado)="data">
                        <div v-if="status == 'C'">
                            {{ isMobile ? '-----' :  '-----------------'}}
                        </div>
                        <div v-else>
                            <span>{{ data.item.entregado }}</span>
                        </div>
                    </template>

                    <template #cell(cargado)="data">

                        <div v-if="status=='C'">
                            {{ isMobile ? '-----' :  '-----------------'}}
                        </div>
                        <div v-else>
                            <span v-if="isEdit">

                                <!-- Si es un retorno, se deshabilita el campo si la cantidad entregada es 0 
                                data.item.rutas_Entregas_Detalles_Id <= 0 || 

                                @input.native="validateItemsToLoad($event, data.item)"
                                -->
                                <b-form-input v-if="isReturn" :ref="'cantidadInput' + data.index" :disabled="data.item.entregado <= 0" 
                                    :id="`article_id-return-${data.item.article_Id}`" size="sm" type="number"
                                    v-model="data.item.cargado" placeholder="0" 
                                    @keydown="handleKeyDown(data.index, $event)"
                                    @input.native="validateItemsToLoad($event, data.item)"/>

                                <b-form-input v-else :ref="'cantidadInput' + data.index" :id="`article_id-${data.item.article_Id}`" :disabled="data.item.oldPending <= 0" size="sm" type="number"
                                v-model="data.item.cargado" placeholder="0"
                                @keydown="handleKeyDown(data.index, $event)"
                                @input.native="validateItemsToLoad($event, data.item)"/>

                            </span>
                            <span v-else>
                                {{ data.item.cargado }}
                            </span>
                        </div>
                    </template>

                    <template #cell(pendiente)="data">
                        <div v-if="status == 'C'">
                            {{ isMobile ? '-----' :  '-----------------'}}
                        </div>
                        <div v-else>
                            <span>{{ data.item.pendiente }}</span>
                        </div>
                    </template>

                    <template #cell(estatus_Retorno)="data">
                        <span class="text-capitalize">{{data.item.estatus_Retorno}}</span>
                    </template>
                </b-table>
            </div>
        </b-overlay>

        <!-- Modal -->
        <b-modal id="details-modal" ref="detailsModal" :title="article.articulo" hide-footer>
            <b-img-lazy :src="convertirABase64(article.imagen)" fluid class="w-100" :alt="article.imagen"/>
        </b-modal>
    </b-overlay>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
    BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
    BBadge, BCardSubTitle, BCardTitle, BTabs, BTab, BImgLazy,
} from 'bootstrap-vue'
import Swal from "sweetalert2";
import "animate.css";
import { v4 as uuidv4 } from 'uuid';
import router from "@/router";
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import store from '@/store';
import ArticlesProvider from '@/providers/Articles'
const ArticlesResource = new ArticlesProvider()


export default {
    name: 'ComponentArticles',
    directives: {
        Ripple,
        'b-modal': VBModal,
        'b-toggle': VBToggle,
    },
    components: {
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardBody,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormTextarea,
        BFormTags,
        BCardCode,
        BAvatar,
        BPagination,
        BFormSelect,
        BBadge,
        BCardSubTitle,
        BCardTitle,
        BTabs,
        BTab,
        BImgLazy,
    },
    mixins: [heightTransition],
    props: {
        isEdit: {
            type: Boolean,
            default: () => false,
        },
        isReturn: {
            type: Boolean,
            default: () => false,
        },
        isPartial: {
            type: Boolean,
            default: () => false,
        },
        showClienteRecibe: {
            type: Boolean,
            default: () => true,
        },
        articles: {
            type: Array,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: () => false,
        },
        hasRouting: {
            type: Boolean,
            default: () => false,
        },
        showAutocompleteCharge: {
            type: Boolean,
            default: () => false,
        },
        isSticky: {
            type: Boolean,
            default: () => false,
        },
        status: {
            type: String,
            default: () => 'N',
            required: true,
        },
    },
    data() {
        return {
            loadingImage: false,
            columns: [
                // {
                //     key: 'imagen',
                //     label: '',
                //     with: '10px!important;',
                // },
                {
                    key: 'um_Total',
                    label: 'Um Total',
                    // tdClass: 'text-center',
                },
                {
                    key: 'articulo',
                    label: 'Articulo',
                    tdStyle: 'font-size: 6px!important'
                },
                {
                    key: 'um',
                    label: 'Um',
                    tdClass: 'text-center',
                },
                {
                    key: 'entregado',
                    label: 'Entregado',
                },
                {
                    key: 'cargado',
                    label: 'Cliente Recibe',
                    thClass: this.showClienteRecibe ? '' : 'd-none',
                    tdClass: this.showClienteRecibe ? '' : 'd-none',
                },
                {
                    key: 'pendiente',
                    label: 'Pendiente',
                },
                // {
                //     key: 'estatus_Retorno',
                //     label: 'Estatus',
                // },
            ],
            isAutoCompleteCharge: false,
            localArticles: this.articles.slice(),
            article: {},
            isMobile: false,
        }
    },
    computed: {
        articlesWithKeys() {
            return this.articles.map(article => ({
                ...article,
                _key: uuidv4(), // Genera un identificador UUID único para cada fila
            }));
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize()
        
        
        //Posicionar el focus en el primer input
        this.$nextTick(() => {
            const nextInput = this.$refs['cantidadInput0'];
            if (nextInput) {
                nextInput.focus(); // Enfoca el siguiente input
                nextInput.select(); // Selecciona el texto del input
            }
        });

    },
    methods: {
        handleResize() {
            this.isMobile = window.innerWidth < 576;
        },
        async viewDetailArticle(item) {
            this.article = {}
            const idEmpresa = store.getters['auth/getConexionId']
            try {
                this.loadingImage = true
                const { data } = await ArticlesResource.ObtenerImagenesArticulo(idEmpresa, item.articulo_Id)
                if (data.isSuccesful) {
                    this.article = data.data
                    this.article.articulo = item.articulo
                    this.$nextTick(() => {
                        this.$refs.detailsModal.show();
                    });
                } else {
                    this.warning(data.message)
                }
            }catch(error) {
                this.handleResponseErrors(error)
            } finally {
                this.loadingImage = false
            }

            // this.article = {}
            // if (item.imagen) {
            //     this.article = item 
            //     this.$nextTick(() => {
            //         this.$refs.detailsModal.show();
            //     });
            // }
        },
        // handleKeyPress(event) {
        //     // Verifica si la tecla presionada es F12
        //     if (event.key === 'F12') {

        //         event.preventDefault();  // Prevenir la acción predeterminada del navegador

        //         // Emula el clic en el botón de guardar
        //         this.$refs.saveButton.click();
        //     }
        // },
        handleKeyDown(index, event) {
            if (event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'Enter') {
                event.preventDefault(); // Evita el comportamiento por defecto del navegador

                let nextIndex = index;
                if (event.key === 'ArrowDown' || event.key === 'Enter') {
                    nextIndex++;
                } else if (event.key === 'ArrowUp') {
                    nextIndex--;
                }

                // Asegúrate de que el índice esté dentro del rango válido
                if (nextIndex >= 0 && nextIndex < this.articles.length) {
                    // Obtén la referencia al siguiente input de la fila
                    this.$nextTick(() => {
                        const nextInput = this.$refs['cantidadInput' + nextIndex];
                        if (nextInput) {
                            nextInput.focus(); // Enfoca el siguiente input
                            nextInput.select(); // Selecciona el texto del input
                        }
                    });
                }
            }
        },
        validateItemsToLoad(e, item) {
            const cantidad = e.target.value ///.toFixed(3)
            if (this.isReturn) {                
                if (isNaN(cantidad) || cantidad < 0 || cantidad > item.entregado) {

                    if (cantidad > item.entregado) {
                        this.warning(`La cantidad ingresada no puede ser mayor a la cantidad entregada (${item.entregado})`)
                    }

                    item.cargado = 0;
                } else {
                    item.cargado = cantidad
                }
            } else { 
                if (isNaN(cantidad) || cantidad < 0 || cantidad > item.oldPending) {

                    if (cantidad > item.oldPending) {
                        this.warning(`La cantidad ingresada no puede ser mayor a la cantidad pendiente (${item.oldPending})`)
                    }

                    item.cargado = 0; // Revertir a 0 si no es un número válido
                    item.pendiente = item.oldPending
                } else {
                    item.cargado = cantidad
                    item.pendiente = parseFloat(item.oldPending - cantidad).toFixed(2)
                }
            }
        },
        autoCompleteCharge() {            
            this.articles.forEach(article => {
                if (article.pendiente <= 0) {
                    return
                }
                article.cargado = article.pendiente
                article.pendiente = 0
            })
        }
    },
    watch: {
        articles(newVal) {
            
        },
        isReturn(value) {

            //Encontrar la columna de cargado y cambiar su label
            const cargadoColumn = this.columns.find(column => column.key === 'cargado')
            if (value) {
                cargadoColumn.label = 'Cliente Entrega'
            } else {
                cargadoColumn.label = 'Cliente Recibe'
            }
        },
        isPartial(value) {
            
            //Encontrar la columna de cargado y cambiar su label
            const cargadoColumn = this.columns.find(column => column.key === 'cargado')
            if (value) {
                cargadoColumn.label = 'Enviar a Cliente'
            } else {
                cargadoColumn.label = 'Cliente Recibe'
            }
        },
        isEdit(value) {
            this.$nextTick(() => {
            const nextInput = this.$refs['cantidadInput0'];
            if (nextInput) {
                nextInput.focus(); // Enfoca el siguiente input
                nextInput.select(); // Selecciona el texto del input
            }
        });
        }
    }

}
</script>

<style>
.b-table-sticky-header {
    overflow-y: auto;
    max-height: 450px;
}
</style>