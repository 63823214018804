<template>
    <div>
        <!-- Modal Search Customer -->
        <b-modal id="modal-search-customer" ref="modal-search-customer" title="Búsqueda Facturas Por Cliente" @hidden="close"
            ok-only ok-title="Aceptar" size="lg" no-close-on-backdrop>
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="12">
                        <v-select
                            id="customer"
                            ref="customer"                                    
                            v-model="searchQuery"
                            :options="customers"
                            label="nombre"
                            :clearable="false"
                            :searchable="true"
                            @search="onSearch"
                            class="select-size-sm"
                            placeholder="Buscar por nombre o clave del cliente"
                            no-options-text="No hay registros">

                            <template #no-options>
                                <b-alert show variant="warning" class="mb-0">
                                    No hay registros
                                </b-alert>
                            </template>

                            <template #option="{ nombre, clave_Cliente }">
                                <span class="ml-50 align-middle"> {{ nombre }} - {{ clave_Cliente }}</span>
                            </template>
                            <template #selected-option="{ nombre, clave_Cliente }">
                                <span class="ml-50 align-middle"> {{ nombre }} - {{ clave_Cliente }}</span>
                            </template>
                        </v-select>
                    </b-col>
                </b-row>
            </div>
            <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
                <div class="table-responsive">
                    <div class="position-relative b-table-responsive mb-0 text-small small table-responsive"
                    :class="charges.length > 10 ? 'b-table-sticky-header' : ''">
                        <table class="table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single">
                            <thead>
                                <tr>
                                    <th>Fecha Factura</th>
                                    <th>Folio</th>
                                    <th>Vencimiento</th>
                                    <th>Atraso</th>
                                    <th>Cargo</th>
                                    <th>Saldo</th>
                                </tr>
                            </thead>
                            <tbody v-if="charges.length > 0">
                                <tr v-for="(item, index) in charges" :key="index" @click="rowSelected(item, index)"
                                :class="{ 'selected-row': isSelected(index) }">
                                    <td class="text-capitalize">{{ customDate(item.fecha, 'DD MMM YYYY') }}</td>
                                    <td>{{ item.factura_Mostrador  ? item.factura_Mostrador : 'F.G.' }}</td>
                                    <td class="text-capitalize">{{ customDate(item.fecha_Vencimiento, 'DD MMM YYYY') }}</td>
                                    <td>{{ item.atraso }}</td>
                                    <td>{{ formatCurrency(item.importe_Cargo) }}</td>
                                    <td>{{ formatCurrency(item.saldo_Cargo) }}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="6" class="text-center">
                                        <h5>No hay registros</h5>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-overlay>
            <small v-if="charges.length > 0" class="text-mutted text-small text-success">Nota: Selecciona una fila para ver el detalle de la factura.</small>
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code'
import {
    BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar,
} from 'bootstrap-vue'
import router from "@/router";
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import vSelect from "vue-select";
import CustomersProvider from "@/providers/Customers"
const CustomersResource = new CustomersProvider()

export default {
    name: 'SearchCustomers',
    directives: {
        Ripple,
        'b-modal': VBModal,
        'b-toggle': VBToggle,
    },
    components: {
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardBody,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormTextarea,
        BFormTags,
        BCardCode,
        BAvatar,

        //
        vSelect,
    },
    mixins: [heightTransition],
    props: ["active"],
    data() {
        return {
            loading: false,
            customers: [],
            columns: [
                { key: "actions", label: ' ' },
                {
                    key: 'clave_Cliente',
                    label: 'Clave Cliente',
                    tdClass: 'text-small',
                },
                {
                    key: "nombre",
                    label: 'Nombre',
                    tdClass: 'text-small',
                },
                {
                    key: "limite_Credito",
                    label: "Límite de Crédito",
                    tdClass: 'text-small',
                },
                {
                    key: "rfc_Curp",
                    label: 'RFC - CURP',
                    tdClass: 'text-small',
                },
                {
                    key: "telefono1",
                    label: 'Teléfono',
                    tdClass: 'text-small',
                },
            ],
            table: null,
            searchQuery: null,
            selectedRow: null,
            refCustomerListTable: null,
            selectableTable: null,
            charges: [],
            selectedRowIndex: -1,
        }
    },
    created() {
        
    },
    mounted() {
       
    },
    methods: {
        // Aplicar debounce a la función onSearch
		onSearch: _.debounce(async function (search, loading) {
			if (search.length >= 3) {
				loading(true);
				await this.searchCustomers(loading, search, this);
                // loading(false)
			}
		}, 300), // 300ms es el tiempo de espera para ejecutar la función después del último evento 'onSearch'
        async searchCustomers(loading, search, vm) {
            const query = {
                searchText: search,
                pageSize: 100,
                currentPage: 1
            }
            this.loading = true;
            const { data } = await CustomersResource.getAll(query)
            this.loading = false
			vm.customers = data.data;
			loading(false)
		},
        rowSelected(item, index) {
            this.$emit("selected", { ...item });
            this.close();
        },
        toggleDetails(item) {
            this.$emit("selected", { ...item });
            this.close();
        },
        onRowClicked(row) {
            this.selectedRow = row
        },
        chooseCustomer(item) {
            this.$emit("selected", { ...item });
            this.close();
        },
        selectFirstRow(){
            this.$refs.selectableTable.selectRow(0)
        },       
        close() {
            this.$refs['modal-search-customer'].hide()
            this.$emit("close");
        },
        async getCharges(customerId) {
            try {
                this.loading = true
                const { data } = await CustomersResource.getCharges(customerId)
                this.loading = false
                if (data.isSuccesful) {
                    this.charges = data.data
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        isSelected(index) {
            return index === this.selectedRowIndex;
        },
    },
    watch: {
        searchQuery(value) {
            this.selectedRowIndex = -1
            this.charges = []
            if (value) {
                this.getCharges(value.cliente_Id)
            }
        },
        active(value) {
            if (value) {
                this.customers = []
                this.searchQuery = null                
                this.$refs['modal-search-customer'].toggle()
            } else {
                this.close()
            }
        }
    }
}
</script>

<style>
.selected-row {
    background-color: #007bff; /* Color de fondo para la fila seleccionada */
    color: white; /* Color de texto para la fila seleccionada */
}
</style>